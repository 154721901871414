import { useCallback, useMemo } from 'react';
import { merge } from 'lodash';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import {
  useSetFieldValue,
  useFieldValues,
  useDimensionField,
  useTokensByType,
  parseFromMultiToStr,
  parseFromStrToMulti,
  isMixedMultiDirectionValue,
  useSetMultiFieldsValues,
  isValidDimensionTextValue,
} from '../../../../hooks';

export const useConstraintsFields = () => {
  const { setMultiFieldsValues } = useSetMultiFieldsValues();

  const TOP_FIELD_KEY = DDPPrimitiveFieldsKeys.top;

  const { value: topFieldValue, initialValue: topFieldInitialValue } =
    useFieldValues(TOP_FIELD_KEY);
  const { saveValue: setTopFieldValue } = useSetFieldValue(TOP_FIELD_KEY);

  const topFieldProps = useDimensionField({
    value: topFieldValue,
    initialValue: topFieldInitialValue,
    saveChanges: setTopFieldValue,
    fieldName: TOP_FIELD_KEY,
    options: {
      supportAutoValue: true,
      supportNegativeValue: true,
      supportNoneValue: true,
    },
  });

  const RIGHT_FIELD_KEY = DDPPrimitiveFieldsKeys.right;

  const { value: rightFieldValue, initialValue: rightFieldInitialValue } =
    useFieldValues(RIGHT_FIELD_KEY);
  const { saveValue: setRightFieldValue } = useSetFieldValue(RIGHT_FIELD_KEY);

  const rightFieldProps = useDimensionField({
    value: rightFieldValue,
    initialValue: rightFieldInitialValue,
    saveChanges: setRightFieldValue,
    fieldName: RIGHT_FIELD_KEY,
    options: {
      supportAutoValue: true,
      supportNegativeValue: true,
      supportNoneValue: true,
    },
  });

  const BOTTOM_FIELD_KEY = DDPPrimitiveFieldsKeys.bottom;

  const { value: bottomFieldValue, initialValue: bottomFieldInitialValue } =
    useFieldValues(BOTTOM_FIELD_KEY);
  const { saveValue: setBottomFieldValue } = useSetFieldValue(BOTTOM_FIELD_KEY);

  const bottomFieldProps = useDimensionField({
    value: bottomFieldValue,
    initialValue: bottomFieldInitialValue,
    saveChanges: setBottomFieldValue,
    fieldName: BOTTOM_FIELD_KEY,
    options: {
      supportAutoValue: true,
      supportNegativeValue: true,
      supportNoneValue: true,
    },
  });

  const LEFT_FIELD_KEY = DDPPrimitiveFieldsKeys.left;

  const { value: leftFieldValue, initialValue: leftFieldInitialValue } =
    useFieldValues(LEFT_FIELD_KEY);
  const { saveValue: setLeftFieldValue } = useSetFieldValue(LEFT_FIELD_KEY);

  const leftFieldProps = useDimensionField({
    value: leftFieldValue,
    initialValue: leftFieldInitialValue,
    saveChanges: setLeftFieldValue,
    fieldName: LEFT_FIELD_KEY,
    options: {
      supportAutoValue: true,
      supportNegativeValue: true,
      supportNoneValue: true,
    },
  });

  const { resolveTokenValue } = useTokensByType(
    'top',
    DesignTokenTypeEnum.dimension
  );

  const allConstraintsValue = useMemo(
    () =>
      parseFromMultiToStr({
        top: topFieldValue,
        right: rightFieldValue,
        bottom: bottomFieldValue,
        left: leftFieldValue,
      }),
    [bottomFieldValue, leftFieldValue, rightFieldValue, topFieldValue]
  );

  const allConstraintsInitialValue = useMemo(
    () =>
      parseFromMultiToStr({
        top: topFieldInitialValue,
        right: rightFieldInitialValue,
        bottom: bottomFieldInitialValue,
        left: leftFieldInitialValue,
      }),
    [
      bottomFieldInitialValue,
      leftFieldInitialValue,
      rightFieldInitialValue,
      topFieldInitialValue,
    ]
  );

  const resolvedAllConstraintsValue = useMemo(() => {
    const parsedTop = resolveTokenValue(topFieldValue) ?? topFieldValue;
    const parsedRight = resolveTokenValue(rightFieldValue) ?? rightFieldValue;
    const parsedBottom =
      resolveTokenValue(bottomFieldValue) ?? bottomFieldValue;
    const parsedLeft = resolveTokenValue(leftFieldValue) ?? leftFieldValue;

    return parseFromMultiToStr({
      top: parsedTop,
      right: parsedRight,
      bottom: parsedBottom,
      left: parsedLeft,
    });
  }, [
    bottomFieldValue,
    leftFieldValue,
    resolveTokenValue,
    rightFieldValue,
    topFieldValue,
  ]);

  const setAllConstraintsFieldValue = useCallback(
    (value: string | undefined) => {
      if (!value) {
        return;
      }

      const parsedValue = parseFromStrToMulti(value);

      setMultiFieldsValues({
        [DDPPrimitiveFieldsKeys.top]: parsedValue.top,
        [DDPPrimitiveFieldsKeys.right]: parsedValue.right,
        [DDPPrimitiveFieldsKeys.bottom]: parsedValue.bottom,
        [DDPPrimitiveFieldsKeys.left]: parsedValue.left,
      });
    },
    [setMultiFieldsValues]
  );

  const isMixedValue = useMemo(
    () => isMixedMultiDirectionValue(allConstraintsValue),
    [allConstraintsValue]
  );

  const isConstraintsComputed = useMemo(
    () =>
      !topFieldValue &&
      !rightFieldValue &&
      !bottomFieldValue &&
      !leftFieldValue,
    [bottomFieldValue, leftFieldValue, rightFieldValue, topFieldValue]
  );

  const allConstraintsFieldProps = useDimensionField({
    value: allConstraintsValue,
    initialValue: allConstraintsInitialValue,
    saveChanges: setAllConstraintsFieldValue,
    fieldName: 'constraints',
    options: {
      supportAutoValue: true,
      supportNegativeValue: true,
      supportNoneValue: true,
      supportMixedValue: true,
    },
  });

  const isValidTextValue = useMemo(
    () => isValidDimensionTextValue(allConstraintsFieldProps.value, true),
    [allConstraintsFieldProps]
  );

  const shouldActivateUnit = useMemo(() => {
    if (isMixedValue) {
      return false;
    }

    return !isValidTextValue;
  }, [isMixedValue, isValidTextValue]);

  return {
    topFieldProps,
    rightFieldProps,
    bottomFieldProps,
    leftFieldProps,
    allConstraintsFieldProps: merge({}, allConstraintsFieldProps, {
      activateUnit: shouldActivateUnit,
      tokensDrawerProps: {
        initialValue: resolvedAllConstraintsValue,
      },
    }),
    rawConstraintsValue: allConstraintsValue,
    parsedRawConstraintsValue: resolvedAllConstraintsValue,
    isConstraintsComputed,
  };
};
