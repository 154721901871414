import { useCallback } from 'react';
import { DesignToken, DesignTokenValue } from '@juxio/design-tokens';
import { useEditTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/createEditHooks';
import { useSelectedTokenState } from '../state/useSelectedTokenState';

export const useEditToken = () => {
  const { setSelectedToken } = useSelectedTokenState();
  const { openEditTokenForm } = useEditTokenForm();

  const editToken = useCallback(
    ({
      name,
      groupPath,
      value,
      description,
      aliasName,
      path,
    }: {
      name: string;
      path: string;
      groupPath: string;
      value: DesignTokenValue;
      description: DesignToken['$description'];
      aliasName?: string;
    }) => {
      setSelectedToken(path);
      openEditTokenForm({
        name,
        groupPath,
        value,
        description,
        aliasName,
      });
    },
    [openEditTokenForm, setSelectedToken]
  );

  return { editToken };
};
