import router from 'next/router';
import { useEventListener } from 'usehooks-ts';
import { useState, useRef, useMemo, useCallback, MouseEvent } from 'react';
import { toast } from '@jux/ui/toast';
import { PATHS } from '@jux/ui/utils/paths';
import {
  Floors,
  useOnClickOutside,
  useOverlaysZIndex,
  useToggleSidebar,
  useTrackEvents,
} from '@jux/ui/hooks';
import {
  useStoreActions,
  sortByCreatedAtAsc,
  storeApi,
  useStore,
} from '@jux/canjux/core';
import { useOpenConfirmationModal } from '..';
import { useCanvasOptionsLocalStorage } from '../editor';
import { useGetCanvasList } from '../editor/hooks';
import { useOpenCanvasItemActionsPopper } from './useOpenCanvasItemActionsPopper';
import { CANVAS_ACTIONS_CLASSNAME } from './CanvasActionMenu';

export const useSidebarProps = () => {
  const { openCanvasItemActionsPopper } = useOpenCanvasItemActionsPopper();
  const { canvasList } = useGetCanvasList();
  const currentCanvasName = useStore((s) => s.currentCanvasName);
  const canvases = useStore((s) => s.canvases);
  const currentCanvas = canvases[currentCanvasName];
  const { deleteCanvasViewport } = useCanvasOptionsLocalStorage();

  const [isCreationMode, setIsCreationMode] = useState(false);

  const {
    commonActions: {
      setCanvasName,
      createCanvas,
      deleteCanvas,
      setCurrentCanvasName,
    },
  } = useStoreActions();

  const { openConfirmationModal } = useOpenConfirmationModal();

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const { isOpen: isSidebarOpen, close } = useToggleSidebar();
  const closeSidebar = useMemo(
    () => (isSidebarOpen ? close : () => {}),
    [close, isSidebarOpen]
  );

  const isTokenSelected = router.pathname === PATHS.TOKENS;

  const zIndex = useOverlaysZIndex({
    id: 'layout-sidebar',
    floor: Floors.sidebar,
  });

  const sortedCanvasList = useMemo(
    () => canvasList.toSorted(sortByCreatedAtAsc),
    [canvasList]
  );

  const { trackEditorSidebarClick, trackTokenSidebarClick } = useTrackEvents();

  const createCanvasEditorRoute = useCallback((canvasId: string) => {
    return `${PATHS.EDITOR}/${canvasId}`;
  }, []);

  useOnClickOutside({
    refOrSelector: [sidebarRef, `.${CANVAS_ACTIONS_CLASSNAME}`],
    handler: closeSidebar,
    eventType: 'mousedown',
    options: {
      eventListenerOptions: {
        capture: true,
      },
    },
  });

  useEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeSidebar();
    }
  });

  const doesCanvasNameExist = useCallback(
    (canvasName: string) => {
      return Object.keys(canvases).includes(canvasName);
    },
    [canvases]
  );

  const reRouteFromSidebar = useCallback(
    ({ blank, path }: { path: string; blank: boolean }) => {
      if (blank) {
        window.open(path, '_blank');
        return;
      }

      router.push(path);
    },
    []
  );

  const handleTokenClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const isMetaKey = e.metaKey || e.ctrlKey;

      trackTokenSidebarClick();

      reRouteFromSidebar({
        blank: isMetaKey,
        path: PATHS.TOKENS,
      });
    },
    [reRouteFromSidebar, trackTokenSidebarClick]
  );

  const handleCanvasItemClick = useCallback(
    ({
      e,
      id,
      canvasName,
    }: {
      e: MouseEvent<HTMLButtonElement>;
      id: string;
      canvasName: string;
    }) => {
      e.preventDefault();
      const isMetaKey = e.metaKey || e.ctrlKey;

      trackEditorSidebarClick(id);

      reRouteFromSidebar({
        blank: isMetaKey,
        path: createCanvasEditorRoute(id),
      });

      if (!isMetaKey) {
        setCurrentCanvasName({ canvasName });
      }
    },
    [
      createCanvasEditorRoute,
      reRouteFromSidebar,
      setCurrentCanvasName,
      trackEditorSidebarClick,
    ]
  );

  const handleOpenNewTab = useCallback(
    (canvasId: string) => {
      return window.open(createCanvasEditorRoute(canvasId), '_blank');
    },
    [createCanvasEditorRoute]
  );

  const onSaveNewCanvasName = useCallback(
    (newCanvasName: string) => {
      if (doesCanvasNameExist(newCanvasName)) {
        toast.error(`Canvas ${newCanvasName} already exists`);
        return false;
      }
      createCanvas({ name: newCanvasName });
      setCurrentCanvasName({ canvasName: newCanvasName });
      router.push(
        createCanvasEditorRoute(storeApi.getState().canvases[newCanvasName].id)
      );
      setIsCreationMode(false);

      return true;
    },
    [
      createCanvas,
      createCanvasEditorRoute,
      doesCanvasNameExist,
      setCurrentCanvasName,
    ]
  );

  const [canvasItemsEditModeDictionary, setCanvasItemsEditModeDictionary] =
    useState<Record<string, boolean>>(() =>
      Object.entries(canvasList).reduce((acc, [, { name }]) => {
        acc[name] = false;
        return acc;
      }, {} as Record<string, boolean>)
    );

  const toggleSingleCanvasItemToEditMode = useCallback((name: string) => {
    setCanvasItemsEditModeDictionary((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const onRenameSubmit = useCallback(
    ({ newName, name }: { name: string; newName: string }) => {
      if (doesCanvasNameExist(newName)) {
        toast.error(`Canvas ${newName} already exists`);
        return false;
      }
      setCanvasName({ originalCanvasName: name, newName });
      if (currentCanvas.name === name) {
        setCurrentCanvasName({ canvasName: newName });
      }
      toggleSingleCanvasItemToEditMode(name);

      return true;
    },
    [
      currentCanvas?.name,
      doesCanvasNameExist,
      setCanvasName,
      setCurrentCanvasName,
      toggleSingleCanvasItemToEditMode,
    ]
  );

  const onConfirmDelete = useCallback(
    (canvasName: string) => {
      if (sortedCanvasList.length === 1) {
        toast.error('Cannot delete your only canvas');
        return;
      }

      if (canvasName === currentCanvas.name) {
        const canvasToRedirectTo = sortedCanvasList[0];

        reRouteFromSidebar({
          path: createCanvasEditorRoute(canvasToRedirectTo.id),
          blank: false,
        });
        setCurrentCanvasName({ canvasName: canvasToRedirectTo.name });
      }
      deleteCanvas({ canvasName: canvasName });

      const canvasToRemoveFromLocalStorage = canvases[canvasName];
      deleteCanvasViewport(canvasToRemoveFromLocalStorage.id);
    },
    [
      canvases,
      createCanvasEditorRoute,
      currentCanvas?.name,
      deleteCanvas,
      deleteCanvasViewport,
      reRouteFromSidebar,
      setCurrentCanvasName,
      sortedCanvasList,
    ]
  );

  const canvasItemsSidebarProps = useMemo(
    () =>
      sortedCanvasList.map(({ id, name }) => ({
        id,
        name,
        onCanvasItemClick: (e: MouseEvent<HTMLButtonElement>) =>
          handleCanvasItemClick({ e, id, canvasName: name }),
        isSelected: !isTokenSelected && currentCanvas?.id === id,
        isRenameModeOn: canvasItemsEditModeDictionary[name],
        onDiscardRename: () => toggleSingleCanvasItemToEditMode(name),
        onRenameSubmit: (newName: string) => {
          return onRenameSubmit({ name, newName });
        },
        onThreeDotClick: (e: MouseEvent<HTMLButtonElement>) =>
          openCanvasItemActionsPopper({
            sourceElement: e.currentTarget,
            id,
            onRename: () => toggleSingleCanvasItemToEditMode(name),
            onDelete: () => {
              openConfirmationModal({
                title: `Are you sure you want to delete?`,
                children: (
                  <p>
                    {`
                    This action will delete all instances placed in ${name}. All source components are saved in the components
                    panel.`}
                  </p>
                ),
                confirmText: 'Yes',
                cancelText: 'No',
                onConfirm: () => {
                  onConfirmDelete(name);
                },
              });
            },
            onOpenNewTab: () => handleOpenNewTab(id),
          }),
      })),
    [
      canvasItemsEditModeDictionary,
      currentCanvas?.id,
      handleCanvasItemClick,
      handleOpenNewTab,
      isTokenSelected,
      onConfirmDelete,
      onRenameSubmit,
      openCanvasItemActionsPopper,
      openConfirmationModal,
      sortedCanvasList,
      toggleSingleCanvasItemToEditMode,
    ]
  );

  return {
    close,
    isSidebarOpen,
    zIndex,
    sidebarRef,
    handleTokenClick,
    canvasItemsSidebarProps,
    setIsCreationMode,
    isCreationMode,
    isTokenSelected,
    onSaveNewCanvasName,
  };
};
