import { useCallback, useMemo } from 'react';
import { DesignToken, DesignTokenValue } from '@juxio/design-tokens';
import { tokenFormByType } from '@jux/ui/components/tokens/token-drawer/forms/tokenFormByType';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { useTokenDrawerSideEffects } from '@jux/ui/components/tokens/token-drawer/useTokenDrawerSideEffects';
import { useTokens, useTrackEvents } from '@jux/ui/hooks';
import { useTokenSetState } from '@jux/ui/state';
import { createPath } from '@jux/ui/utils/tokensPath';
import { useSelectedTokenState } from '../../../tokensSets/state';

export const useEditTokenForm = () => {
  const { close: closeTokenDrawer, open: openTokenDrawer } = useTokenDrawer();
  const { currentTokenType, currentTokenSetId, isCoreTokenSet } =
    useTokenSetState();
  const {
    groupPathOptionsByType,
    existingTokenPaths,
    existingCoreTokenPaths,
    valuesMap,
    tokenSetDesignTokens,
  } = useTokens();
  const { onSave } = useTokenDrawerSideEffects();
  const { deselectToken } = useSelectedTokenState();

  const { trackTokenUpdatedEvent } = useTrackEvents();

  const EditTokenForm = useMemo(
    () => tokenFormByType[currentTokenType],
    [currentTokenType]
  );

  const openEditTokenForm = useCallback(
    ({
      name,
      groupPath,
      value,
      description,
      aliasName,
    }: {
      name: string;
      groupPath: string;
      value: DesignTokenValue;
      description: DesignToken['$description'];
      aliasName?: string;
    }) =>
      openTokenDrawer({
        content: (
          <EditTokenForm
            /*
             In order to redraw the token form when switching from one token to another while the token drawer is opened,
             it is necessary to distinguish between the different tokens, so react will redraw the form.
            */
            key={createPath([groupPath, name])}
            initialValues={{
              // using any because the value is different for each type, so each form should handle it 🦊
              value: value as any,
              alias: aliasName,
              tokenSetId: currentTokenSetId,
              tokenName: name,
              previousTokenName: name,
              groupPath,
              previousGroupPath: groupPath,
              description,
            }}
            groupPathOptions={groupPathOptionsByType[currentTokenType]}
            existingTokenPaths={existingTokenPaths.filter(
              (p) => p !== createPath([groupPath, name])
            )}
            existingCoreTokenPaths={existingCoreTokenPaths.filter(
              (p) => p !== createPath([groupPath, name])
            )}
            valuesMap={valuesMap}
            tokens={tokenSetDesignTokens}
            isCoreTokenSet={isCoreTokenSet}
          />
        ),
        handlers: {
          onSave: () => {
            onSave(`Token saved successfully`);
            deselectToken();
            trackTokenUpdatedEvent(currentTokenType);
          },
          onCancel: () => {
            closeTokenDrawer();
            deselectToken();
          },
          onClose: () => {
            closeTokenDrawer();
            deselectToken();
          },
        },
      }),
    [
      EditTokenForm,
      closeTokenDrawer,
      currentTokenSetId,
      currentTokenType,
      deselectToken,
      existingCoreTokenPaths,
      existingTokenPaths,
      groupPathOptionsByType,
      isCoreTokenSet,
      onSave,
      openTokenDrawer,
      tokenSetDesignTokens,
      trackTokenUpdatedEvent,
      valuesMap,
    ]
  );

  return {
    closeEditTokenForm: closeTokenDrawer,
    openEditTokenForm,
  };
};
