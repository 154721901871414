import {
  CanjuxState,
  CommonActionsParams,
  deleteNodeAndComponent,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

/**
 * Delete a node, its component data, and its children.
 */
export const deleteNode: JuxStoreActionFn<
  CommonActionsParams['deleteNode'],
  CanjuxState
> = ({ canvasName, nodeId, shouldForceDelete, state }) => {
  const nodeComponentToDelete = state.components[nodeId];
  const canvas = state.canvases[canvasName ?? state.currentCanvasName];

  const isDeletable = canvas.nodes[nodeId]?.properties.isDeletable;
  if (!nodeComponentToDelete || (!isDeletable && !shouldForceDelete)) {
    return state;
  }

  const parent = nodeComponentToDelete?.parentId
    ? state.components[nodeComponentToDelete.parentId]
    : undefined;

  // Do not allow deleting a variant from a matrix
  if (parent?.type === NodeType.VARIANTS_GROUP) {
    return state;
  }

  switch (nodeComponentToDelete.type) {
    case NodeType.VARIANTS_GROUP:
      // delete all variants
      for (const childId of nodeComponentToDelete.children) {
        deleteNodeAndComponent({
          canvas,
          nodeId: childId,
          shouldDeleteComponent: true,
          state,
        });
      }

      // delete the variants group
      deleteNodeAndComponent({
        canvas,
        nodeId,
        shouldDeleteComponent: true,
        state,
      });
      break;
    case NodeType.VARIANT_INSTANCE:
      if (!nodeComponentToDelete?.sourceComponentId) {
        // invalid variant instance - not supposed to happen
        return state;
      }

      // If trying to delete a node inside a matrix, set the original node to be deleted.
      // If the variant instance node was created with isDeletable it means the original source is also deletable,
      deleteNodeAndComponent({
        canvas,
        nodeId: nodeComponentToDelete.sourceComponentId,
        shouldDeleteComponent: true,
        state,
      });
      break;
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LIBRARY_COMPONENT:
      // when deleting the root of a component, do not delete the actual component,
      // we don't want to delete the source component, only the canvas node - do this for its children too.
      deleteNodeAndComponent({
        canvas,
        nodeId,
        shouldDeleteComponent: false,
        state,
      });
      break;
    case NodeType.INSTANCE:
    case NodeType.ELEMENT:
      deleteNodeAndComponent({
        canvas,
        nodeId,
        shouldDeleteComponent: true,
        state,
      });
      break;
  }

  setLayersData(state);
  return state;
};
