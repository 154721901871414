import { forwardRef, memo, PropsWithChildren, useMemo } from 'react';
import { ComponentSourceData, ComponentProps } from '@jux/data-entities';
import { createDrimz } from '@jux/dui';
import { getJuxComponentElement } from '@jux/react';
import { useCalculatedStyles } from '@jux/canjux/core';
import { useNodeLiveModeProps } from '@jux/ui/components/editor/hooks/useNodeLiveModeProps';
import logger from '@jux/ui-logger';
import { liveModeWrapperHOC } from './liveNodeWrappers';

const { styled } = createDrimz<any>();

export type ElementProps = Pick<ComponentSourceData, 'tagName' | 'styles'> &
  PropsWithChildren<{
    elementProps?: ComponentProps;
    nodeId: string;
  }>;

const ControlledElement = forwardRef<HTMLElement, ElementProps>(
  ({ nodeId, children, elementProps, styles, tagName }, ref) => {
    const ElementByTag = getJuxComponentElement(tagName);
    const calculatedStyles = useCalculatedStyles(styles);
    const { nodesLiveModeProps } = useNodeLiveModeProps(nodeId, tagName);

    const StyledElement = useMemo(
      () =>
        ElementByTag
          ? styled(ElementByTag, {
              styles: calculatedStyles,
            })
          : null,
      [ElementByTag, calculatedStyles]
    );

    const Wrapped = useMemo(
      () =>
        StyledElement
          ? liveModeWrapperHOC(StyledElement, {
              tagName,
            })
          : null,
      [StyledElement, tagName]
    );

    if (!Wrapped) {
      logger.error('Unsupported element tag:', tagName);
      return null;
    }

    return (
      <Wrapped {...(elementProps as any)} {...nodesLiveModeProps} ref={ref}>
        {children}
      </Wrapped>
    );
  }
);

// TODO: Optimize even more (compare only relevant props) and reduce unnecessary re-renders
export default memo(ControlledElement);

// new element wont render when moving between live and edit mode
