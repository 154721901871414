export enum NodeType {
  // An element is a node that is not used as a source of truth for other components
  ELEMENT = 'element',

  // A library component is published to developers as a standalone component
  LIBRARY_COMPONENT = 'libraryComponent',

  // A local component is a component that is reusable and has
  // instances which preserve its styling, but is not published
  // to developers as a standalone component
  LOCAL_COMPONENT = 'localComponent',

  // An instance is an instance of a component or an element,
  // but it can also be a source of truth for other components,
  // i.e. nth level of inheritance
  INSTANCE = 'instance',

  // a node that shows editable variants matrix
  VARIANTS_GROUP = 'variantsGroup',
  VARIANT_INSTANCE = 'variantInstance',

  LOGICAL_SLOT = 'logicalSlot',
}

export const SOURCE_COMPONENT_TYPES = [
  NodeType.LOCAL_COMPONENT,
  NodeType.LIBRARY_COMPONENT,
];
