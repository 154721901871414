import {
  JuxStore,
  JuxStoreActionFn,
  TokenSetsActionsParams,
  toRecordBy,
} from '@jux/canjux/core';
import {
  getCurrentTimestamp,
  namingPatternsSchema,
} from '@juxio/design-tokens';
import { z } from 'zod';

export const renameTokenSet: JuxStoreActionFn<
  TokenSetsActionsParams['renameTokenSet'],
  JuxStore
> = ({ state, ...params }) => {
  const { tokenSetId: id, name } = z
    .object({
      tokenSetId: z.string(),
      name: namingPatternsSchema,
    })
    .parse(params);

  const tokenSetsById = toRecordBy(state.tokenSets, 'id');

  tokenSetsById[id].name = name;

  tokenSetsById[id].updatedAt = getCurrentTimestamp();

  return state;
};
