import { getDefaultNodeData, JuxStore } from '@jux/canjux/core';
import {
  ComponentConfigData,
  ComponentInstanceData,
  ComponentTagNames,
  NodeData,
  NodeType,
  VariantsConfig,
  XYPosition,
} from '@jux/data-entities';
import type { Draft as WritableDraft } from 'mutative';
import { v4 as uuidv4 } from 'uuid';
import { addStorageNode } from '../../store.changes.utils';
import { addInstanceNodes, setRootComponentUpdateTime } from '../actions/utils';

const recursiveAddInstanceChildren = ({
  affectedCanvases,
  parentNodeId,
  sourceComponentChildren,
  state,
}: {
  affectedCanvases: string[];
  parentNodeId: string;
  sourceComponentChildren: string[];
  state: WritableDraft<JuxStore>;
}) => {
  const { components, canvases } = state;

  return sourceComponentChildren.map((childId) => {
    const childSource = components[childId];
    if (!childSource) {
      throw new Error(
        'Cannot create new instance, one of the layers does not exist'
      );
    }

    const newNodeId = uuidv4();
    const newInstance: ComponentInstanceData = {
      id: newNodeId,
      type: NodeType.INSTANCE,
      sourceComponentId: childId,
      parentId: parentNodeId,
      config: {
        props: [], // don't set props for children, only for the root instance
      },
      children: recursiveAddInstanceChildren({
        affectedCanvases,
        parentNodeId: newNodeId,
        sourceComponentChildren: childSource.children,
        state,
      }),
    };

    const newNode: NodeData = {
      properties: {
        isContainer: false, // inner nodes are immutable and cannot be changed
        isDeletable: false, // inner children are not deletable
        isDraggable: false, // inner children are not draggable
        isDragged: false, // inner children are not draggable
        isHidden: false,
        isImmutable: true,
        isSelectable: true,
      },
    };

    components[newInstance.id] = newInstance;

    for (const canvasName of affectedCanvases) {
      canvases[canvasName].nodes[newInstance.id] = newNode;
    }

    return newNodeId;
  });
};

/**
 * Get valid props values for the instance (default or overridden)
 * @param variantsConfig
 * @param propsOverrides
 */
const validPropsValuesOverrides = ({
  variantsConfig,
  propsOverrides,
}: {
  variantsConfig: VariantsConfig;
  propsOverrides?: ComponentConfigData['props'];
}) => {
  const props: ComponentConfigData['props'] = {};
  for (const variant of variantsConfig) {
    if (
      // If valid override exists
      propsOverrides &&
      variant.variant in propsOverrides &&
      variant.options.find(
        (option) => option.value === propsOverrides[variant.variant]
      )
    ) {
      props[variant.variant] = propsOverrides[variant.variant];
    } else {
      props[variant.variant] = variant.defaultValue;
    }
  }

  return props;
};

/**
 * Create a new instance of a component on the canvas
 * If parentId is provided, the new instance will be added to all canvases containing the parentId,
 * Otherwise, If canvasName is provided- new node without a parent will be created there.
 * If neither parentId nor canvasName is provided, the current canvas will be used
 * @param canvasName
 * @param componentId
 * @param propsOverrides - overrides for the instance variant
 * @param parentId
 * @param position - if no parent id is provided, the position will be used for the root node
 * @param targetIndex - index in the parent's children list to push the new instance to
 * @param state
 */
export const createComponentInstanceCanvasNode = ({
  canvasName,
  componentId,
  propsOverrides,
  parentId,
  position,
  targetIndex = -1,
  state,
}: {
  canvasName?: string;
  componentId: string;
  propsOverrides?: ComponentConfigData['props'];
  parentId?: string;
  position?: XYPosition;
  state: WritableDraft<JuxStore>;
  targetIndex?: number;
}) => {
  const { components, canvases } = state;
  const sourceComponent = components[componentId];
  if (
    !sourceComponent ||
    (sourceComponent.type !== NodeType.LIBRARY_COMPONENT &&
      sourceComponent.type !== NodeType.LOCAL_COMPONENT)
  ) {
    return null;
  }

  const affectedCanvases: string[] = [];
  if (parentId) {
    for (const [id, canvas] of Object.entries(canvases)) {
      if (Object.keys(canvas.nodes).includes(parentId)) {
        affectedCanvases.push(id);
      }
    }
  } else {
    affectedCanvases.push(canvasName ? canvasName : state.currentCanvasName);
  }

  const newNodeId = uuidv4();
  const rootInstanceNode = getDefaultNodeData({
    parentId,
    position,
  });

  for (const affectedCanvas of affectedCanvases) {
    canvases[affectedCanvas].nodes[newNodeId] = rootInstanceNode;
  }
  const childrenIds = recursiveAddInstanceChildren({
    affectedCanvases,
    sourceComponentChildren: sourceComponent.children,
    parentNodeId: newNodeId,
    state,
  });

  if (sourceComponent.tagName === ComponentTagNames.JuxSvg) {
    // Asset components should be copied as elements, not instances
    components[newNodeId] = {
      type: NodeType.ELEMENT,
      config: {
        props: {},
      }, // content should be derived from the asset data by sourceComponentId
      displayName: sourceComponent.displayName,
      id: newNodeId,
      sourceComponentId: sourceComponent.id,
      parentId: parentId,
      scopes: sourceComponent.scopes,
      styles: sourceComponent.styles,
      tagName: sourceComponent.tagName,
      children: [],
    };
  } else {
    components[newNodeId] = {
      id: newNodeId,
      type: NodeType.INSTANCE,
      sourceComponentId: componentId,
      displayName: sourceComponent.displayName,
      parentId: parentId,
      config: {
        props: {
          ...sourceComponent.config.props,
          ...validPropsValuesOverrides({
            variantsConfig: sourceComponent.config.variants ?? [],
            propsOverrides,
          }),
        },
      },
      children: childrenIds,
    };
  }

  // Root node - add it to the canvas's nodes order list
  if (!parentId) {
    for (const affectedCanvas of affectedCanvases) {
      canvases[affectedCanvas].rootNodesOrder.unshift(newNodeId);
    }
  } else {
    // Nested node - add it to the parent's children list
    const parentNode = components[parentId];
    if (!parentNode.children.includes(newNodeId)) {
      addStorageNode(parentNode.children, newNodeId, targetIndex);
    }

    // if created under a parent component
    setRootComponentUpdateTime({ id: parentId, components });

    addInstanceNodes({
      sourceNodeId: newNodeId,
      state,
      targetIndex,
      targetNodeId: parentId,
    });
  }

  return newNodeId;
};
