import { FC, useCallback, useMemo } from 'react';
import { z } from 'zod';
import { DesignTokenTypeEnum, formatToAlias } from '@juxio/design-tokens';
import { useStoreActions } from '@jux/canjux/core';
import { CORE } from '@jux/types';
import {
  FieldContainer,
  StyledSelectField,
  StyledTextField,
  TITLE_CREATE_NEW_TOKEN,
  TITLE_EDIT_TOKEN,
  TokenDrawerFormLayout,
  TokenDrawerIconLabel,
  TokenNameField,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  ALIAS_PLACEHOLDER_TEXT,
  FONT_FAMILY_PATH_PREFIX,
  formatAliasPath,
  formatGroupPath,
  GetValueFn,
  parseFontFamilyTokenValue,
  useFontFamilyOptions,
  useFormValueAlias,
  useInitialValues,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { formatFontFamilyTokenValue } from '@jux/ui/components/tokens/token-drawer/forms/helpers/formatFontFamilyTokenValue';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { fontFamilyTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

const fontFamilyTokenFormSchema = fontFamilyTokenInputSchema.extend({
  value: z.string(),
});

type FontFamilyTokenFormProps = TokenFormProps<
  typeof fontFamilyTokenInputSchema
>;

export const FontFamilyTokenForm: FC<FontFamilyTokenFormProps> = ({
  initialValues,
  groupPathOptions,
  existingTokenPaths = [],
  existingCoreTokenPaths = [],
  valuesMap,
  isCoreTokenSet,
}) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const form = useZodForm({
    schema: fontFamilyTokenFormSchema,
    defaultValues: {
      ...initialValues,
      value: parseFontFamilyTokenValue(initialValues.value),
    },
  });

  const { options: fontFamilies, fallbacks } = useFontFamilyOptions();

  const { handlers } = useTokenDrawer();

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerValueField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    {
      alias: {
        onChange: (e) =>
          handlers.onChange?.({ newValue: formatToAlias(e.target.value) }),
      },
    },
    {
      value: {
        onChange: (e) => handlers.onChange?.({ newValue: e.target.value }),
      },
    },
    'description',
  ]);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [value] = useInitialValues(['value'], {
    form,
    initialValues: {
      ...initialValues,
      value: parseFontFamilyTokenValue(initialValues.value),
    },
  });

  const getValue: GetValueFn = useCallback(
    (valuePath) =>
      parseFontFamilyTokenValue(
        valuesMap[
          valuePath
        ] as FontFamilyTokenFormProps['initialValues']['value']
      ),
    [valuesMap]
  );

  const existingFontFamilyTokens = useMemo(() => {
    if (isCoreTokenSet) {
      return existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, FONT_FAMILY_PATH_PREFIX]))
      );
    }

    return existingTokenPaths.filter((p) =>
      p.startsWith(FONT_FAMILY_PATH_PREFIX)
    );
  }, [existingCoreTokenPaths, existingTokenPaths, isCoreTokenSet]);

  const existingCoreFontFamilyTokens = useMemo(
    () =>
      existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, FONT_FAMILY_PATH_PREFIX]))
      ),
    [existingCoreTokenPaths]
  );

  const { aliasOptionsProps, hasAliases, aliasValue, isAliasToken } =
    useFormValueAlias<typeof form, typeof fontFamilyTokenInputSchema>({
      form,
      getValue,
      existingTokenPaths: existingFontFamilyTokens,
      existingCoreTokenPaths: existingCoreFontFamilyTokens,
      isCoreTokenSet,
    });

  const validateTokenName = useValidateTokenName({
    form,
    existingTokenPaths: existingFontFamilyTokens,
  });

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit((values) => {
        if (validateTokenName()) {
          const fontFamilyValue = formatFontFamilyTokenValue({
            value: values.value,
            fallbacks: fallbacks[values.value],
          });

          setToken({
            data: {
              ...values,
              alias: formatAliasPath(values.alias),
              isCore: isCoreTokenSet,
              value: fontFamilyValue,
            },
            type: DesignTokenTypeEnum.fontFamily,
          });

          handlers.onSave(
            getUpdatedTokenData({
              tokenPath: createPath([values.groupPath, values.tokenName]),
              tokenSetId: values.tokenSetId,
            })
          );
        }
      }, validateTokenName),
    [form, validateTokenName, fallbacks, setToken, isCoreTokenSet, handlers]
  );

  return (
    <TokenDrawerFormLayout
      title={
        initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN
      }
      onSubmit={handleSubmit}
    >
      <TokenDrawerIconLabel iconVariant="FONT_FAMILY" labelText="Font Family" />
      <FieldContainer error={form.formState.errors.tokenName?.message}>
        <TokenNameField
          error={Boolean(form.formState.errors.tokenName)}
          inputRef={registerTokenNameField().ref}
          {...registerTokenNameField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message}>
        <StyledSelectField
          placeholder="Folder"
          defaultValue={initialValues.groupPath}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.groupPath)}
          inputRef={registerGroupPathField().ref}
          options={groupPathOptions}
          disableMenuPortal
          {...registerGroupPathField()}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelectField
          disabled={!hasAliases}
          placeholder={ALIAS_PLACEHOLDER_TEXT}
          value={aliasValue}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.alias)}
          inputRef={registerAliasField().ref}
          disableMenuPortal
          {...aliasOptionsProps}
          {...registerAliasField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.message}>
        <StyledSelectField
          placeholder="Font family"
          disabled={isAliasToken}
          value={value}
          error={Boolean(form.formState.errors.value)}
          options={fontFamilies}
          inputRef={registerValueField().ref}
          disableMenuPortal
          {...registerValueField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message}>
        <StyledTextField
          inputProps={{
            placeholder: 'Token description',
          }}
          error={Boolean(form.formState.errors.description)}
          inputRef={registerDescriptionField().ref}
          {...registerDescriptionField()}
        />
      </FieldContainer>
    </TokenDrawerFormLayout>
  );
};
