import { CanjuxState, Dimensions, getRootNodeOfNode } from '@jux/canjux/core';
import {
  ComponentSourceData,
  JuxComponentData,
  NodeProperties,
  NodeType,
  XYPosition,
} from '@jux/data-entities';
import deepmerge from 'deepmerge';
import omit from 'lodash/omit';
import { createSelector } from 'reselect';
import { getLocalComponentDependencies } from '../helpers/getLocalComponentDependencies';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';

export type LayerNodeData = JuxComponentData & {
  dimensions: Dimensions & { positionAbsolute: XYPosition };
  position?: XYPosition;
  isContainsLocalComponentInstance: boolean;
  isInstanceToSourceComponent: boolean;
  isNodeInLibrary: boolean;
  properties: NodeProperties;
  rootNodeType: NodeType;
};

export const selectLayersPanelItems = createSelector(
  [
    (state: CanjuxState) => state.components,
    (state: CanjuxState) => state.canvases[state.currentCanvasName]?.nodes,
    (state: CanjuxState) => state.canvasNodesIndicatorsPositions,
    (state: CanjuxState) => state.canvasNodesDimensions,
  ],
  (components, nodes, canvasNodesIndicatorsPositions, canvasNodesDimensions) =>
    nodes &&
    Object.entries(nodes).reduce((acc, [id, data]) => {
      const component = components[id];

      const sourceComponent = getResolvedSourceComponentData({
        component,
        components,
      });
      if (!sourceComponent) {
        return acc;
      }

      const isInstanceToSourceComponent =
        sourceComponent.type === NodeType.LOCAL_COMPONENT ||
        sourceComponent.type === NodeType.LIBRARY_COMPONENT;

      const rootNodeOfSource = getRootNodeOfNode({
        nodeId: sourceComponent.id,
        components,
      });
      const isNodeInLibrary =
        rootNodeOfSource &&
        rootNodeOfSource?.type === NodeType.LIBRARY_COMPONENT;

      const isContainsLocalComponentInstance =
        getLocalComponentDependencies({
          componentId: id,
          components,
        }).length > 0;

      const rootNode = getRootNodeOfNode({ nodeId: id, components });
      const rootNodeType = rootNode ? rootNode?.type : NodeType.LOCAL_COMPONENT;

      const mergedComponent = deepmerge<JuxComponentData>(
        omit(sourceComponent, [
          'children',
          'id',
          'parentId',
        ]) as JuxComponentData,
        component
      );

      acc[id] = {
        ...data,
        ...(mergedComponent as ComponentSourceData),
        dimensions: {
          ...(canvasNodesDimensions[id] || { height: 0, width: 0 }),
          positionAbsolute: canvasNodesIndicatorsPositions[id]
            ?.positionAbsolute || {
            x: 0,
            y: 0,
          },
        },
        isContainsLocalComponentInstance,
        isNodeInLibrary: isNodeInLibrary,
        isInstanceToSourceComponent,
        rootNodeType,
      };

      return acc;
    }, {} as Record<string, LayerNodeData>)
);
