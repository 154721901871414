import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';
// importing from '../hooks' breaks the ui-playground
import { usePersistLocalStorageDataEffect } from '../hooks/usePersistLocalStorageDataEffect';

type Settings = {
  borderToken?: boolean;
  direction?: 'ltr' | 'rtl';
  fpsCounter?: boolean;
  presence?: boolean;
  responsiveFontSizes?: boolean;
  theme: 'light' | 'dark';
  variantsGroup?: boolean;
};

const defaultSettings: Settings = {
  borderToken: false,
  direction: 'ltr',
  fpsCounter: false,
  presence: false,
  responsiveFontSizes: true,
  theme: 'light',
  variantsGroup: false,
};

const LOCAL_STORAGE_KEY = 'settings';

const settingsAtom = atomWithStorage<Settings>(
  LOCAL_STORAGE_KEY,
  defaultSettings
);

export const useSettingsState = () => {
  const [settings, setSettings] = useAtom(settingsAtom);

  const toggleTheme = useCallback(() => {
    setSettings((prev) => ({
      ...prev,
      theme: prev.theme === 'light' ? 'dark' : 'light',
    }));
  }, [setSettings]);

  usePersistLocalStorageDataEffect({
    key: LOCAL_STORAGE_KEY,
    defaultData: defaultSettings,
    setData: setSettings as any, // some issue with jotai types
  });

  return {
    settings,
    toggleTheme,
  };
};
