import { JuxStore } from '@jux/canjux/core';
import {
  DesignToken,
  getCurrentTimestamp,
  SupportedTokenTypes,
} from '@juxio/design-tokens';
import { bulkUpsertTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';
import cloneDeep from 'lodash/cloneDeep';
import { create, Draft as WritableDraft } from 'mutative';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';

const designTokensParser = getDesignTokensParserContext();

export const bulkUpsertToken = <
  T extends SupportedTokenTypes,
  // TODO: Make this inferred according to the type param
  D extends z.infer<typeof bulkUpsertTokenInputSchema>
>({
  state,
  tokenData: {
    alias,
    description,
    groupPath,
    isCore,
    previousGroupPath,
    previousTokenName,
    tokenName,
    tokenSetId,
    value,
  },
  tokenType,
}: {
  state: WritableDraft<JuxStore>;
  tokenData: D;
  tokenType: T;
}) => {
  const oldGroupPath = previousGroupPath || groupPath;

  const oldPath =
    oldGroupPath === ''
      ? previousTokenName || tokenName
      : createPath([oldGroupPath, previousTokenName || tokenName]);

  const newPath = createPath([groupPath, tokenName]);

  return create(state.tokenSets, (draft) => {
    for (const tokenSetName in draft) {
      const tokenSet = draft[tokenSetName];
      const parser = designTokensParser.parse(cloneDeep(tokenSet.value));
      const isRename = oldPath !== newPath;

      // Setting the value if it's a core token,
      // or if this is the token set that's being edited,
      // or if the token doesn't exist in the set.
      if (isCore || tokenSet.id === tokenSetId || !parser.has(oldPath)) {
        const tokenData: DesignToken = {
          $value: alias || value,
          $type: tokenType,
        };

        if (description !== undefined) {
          tokenData.$description = description;
        }

        parser.setToken({
          allowOverwrite: parser.has(oldPath),
          tokenData,
          tokenPath: oldPath,
        });
      }

      // if the token name or group path has changed, rename the token
      if (isRename) {
        parser.renameToken({
          oldPath,
          newPath,
        });
      }

      tokenSet.value = parser.getRawValueCopy();

      tokenSet.updatedAt = getCurrentTimestamp();

      draft[tokenSet.name] = tokenSet;
    }
  });
};
